<template>
  <div class="goods" v-loading="loading">
    <el-select
      placeholder="商品状态"
      v-model="goodsInfo.status"
      style="margin-right: 20px; width: 180px"
    >
      <el-option
        v-for="item in productStatusList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-input
      class="searchByType"
      style="width: 360px; margin-right: 10px; margin-top: 3px"
      v-model="searchByAsin"
      placeholder="请输入ASIN"
    >
      <el-select slot="prepend" v-model="select_Asin" style="width: 90px">
        <el-option
          v-for="item in searchList"
          :key="item.id"
          :label="item.value"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-input>
    <el-button @click="find" type="primary">查询</el-button><br />
    <div class="goodsBox">
      <div>
        <div class="goodTop">
          <span style="margin-left:10px">商品列表</span>
          <span @click="addAll">添加全部</span>
        </div>
        <el-table
          :data="goodsList"
          style="width: 100%; flex: 1"
          height="600px"
          cell-style="padding:7px 0;text-align:left"
          :show-header="false"
          header-cell-style="text-align:center"
        >
          <el-table-column
            align="left"
            prop="imageUrl"
            label="label"
            width="210"
          >
            <template slot-scope="scope">
              <GoodsImage
                :marketPlaceImageUrl="scope.row.marketPlaceImageUrl"
                :label="scope.row.label"
                :parentASIN="scope.row.parentAsin"
                :imageUrl="scope.row.imageUrl"
                :asin="scope.row.asin"
                :title="scope.row.title"
                :commoditySku="scope.row.commoditySku"
              ></GoodsImage>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="label"
          >
            <template slot-scope="scope">
              <div style="color: #999999; font-size: 14px">
                <p>
                  可售库存：<span style="color: gray">{{
                    scope.row.total
                  }}</span>
                </p>
                <p>
                  可售天数：<span style="color: gray">{{
                    scope.row.canSellDay
                  }}</span>
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="label"
            width="130px"
          >
            <template slot-scope="scope">
              <p style="color: #999999; font-size: 14px">
                售价：<span style="color: gray"
                  >{{ currency }}{{ scope.row.sellPrice }}</span
                >
              </p>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="label"
            width="45px"
          >
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.option == 0"
                @click.self.native="addGoods(scope.row, scope.$index)"
                style="font-size: 20px"
                type="text"
                class="el-icon-circle-plus-outline"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 4px 4px; text-align: right; margin-top: 10px">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="goodsInfo.current"
            :page-size="10"
            layout="total, prev, pager, next"
            :total="total"
            :pager-count="5"
          >
          </el-pagination>
        </div>
      </div>
      <div>
        <div class="goodTop">
          <span style="margin-left: 10px">已添加({{ length }})</span>
          <span @click="removeAll">删除全部</span>
        </div>
        <el-table
          :data="addedList"
          style="width: 100%; flex: 1"
          height="600px"
          cell-style="padding:7px 0;text-align:left"
          :show-header="false"
          header-cell-style="text-align:center"
        >
          <el-table-column
            align="left"
            prop="imageUrl"
            label="label"
            width="210"
          >
            <template slot-scope="scope">
              <GoodsImage
                :marketPlaceImageUrl="scope.row.marketPlaceImageUrl"
                :label="scope.row.label"
                :parentASIN="scope.row.parentAsin"
                :imageUrl="scope.row.imageUrl"
                :asin="scope.row.asin"
                :title="scope.row.title"
                :commoditySku="scope.row.commoditySku"
              ></GoodsImage>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="label"
          >
            <template slot-scope="scope">
              <div style="color: #999999; font-size: 14px">
                <p>
                  可售库存：<span style="color: gray">{{
                    scope.row.total
                  }}</span>
                </p>
                <p>
                  可售天数：<span style="color: gray">{{
                    scope.row.canSellDay
                  }}</span>
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="label"
            width="130px"
          >
            <template slot-scope="scope">
              <p style="color: #999999; font-size: 14px">
                售价：<span style="color: gray"
                  >{{ currency }}{{ scope.row.sellPrice }}</span
                >
              </p>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="prop"
            label="label"
            width="65px"
          >
            <template slot-scope="scope">
              <el-button
                @click.once.self.native="remove(scope.row, scope.$index)"
                v-show="scope.row.option == 1"
                style="font-size: 20px"
                type="text"
                class="el-icon-delete"
              ></el-button>
              <span
                v-show="scope.row.yet == 1"
                style="font-size: 13px; color: #999999"
                >已存在</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import GoodsImage from "@/components/GoodsImage.vue";
import {
  pageInit, // 查询初始化
  listPage
} from "@/api/InventoryManage/stockControl.js";
import { listByGroupId } from "@/api/launch.js";
export default {
  components: {
    GoodsImage
  },
  props: {
    shopInfo: {
      required: true,
      type: Object
    },
    type: {
      required: true,
      type: String //add是只加   get 是获取和加
    },
    groupId: {
      type: Number
    }
  },
  watch: {
    groupId(newval, oldval) {
      this.getAddedList();
      this.getListPage();
    }
  },
  data() {
    return {
      loading: false,
      productStatusList: [],
      subASINList: "",
      goodsInfo: {
        status: "",
        current: 1,
        queryFrom: "advert"
      },
      total: 0,
      searchByAsin: "",
      select_Asin: "asin",
      radioState: "",
      goodsList: [],
      imageUrl: "",
      addedList: []
    };
  },
  mounted() {
    this.getPageInit();
  },
  updated() {
    console.log();
  },
  computed: {
    length() {
      let num = this.addedList.filter(item => {
        return item.yet != 1;
      });
      return num.length;
    }
  },
  methods: {
    sendAsin() {
      let arr = this.addedList.map(item => {
        return item.asin;
      });
      this.$emit("getAsinList", arr);
    },
    removeAll() {
      this.addedList = this.addedList.filter(item => {
        return item.yet == 1;
      });
      this.goodsList.forEach(item => {
        item.option=0;
      });
      this.sendAsin();
    },
    addAll() {
      let arr = this.addedList.map(item => {
        return item.asin;
      });
      this.goodsList.forEach(item => {
        item.option = 1;
        if (!arr.includes(item.asin)) {
          this.addedList.push(item);
        }
      });
      this.sendAsin();
    },
    remove(info, index) {
      this.addedList.splice(index, 1);
      this.goodsList.forEach((item, ind) => {
        if (item.asin == info.asin) {
          item.option = 0;
        }
      });
      let arr = this.addedList.map(item => {
        return item.asin;
      });
      this.sendAsin();
    },
    addGoods(info, index) {
      this.goodsList[index].option = 1;
      this.addedList.push({ ...info, option: 1 });

      let arr = this.addedList.map(item => {
        return item.asin;
      });
      this.sendAsin();
    },
    getAddedList() {
      this.loading = true;
      listByGroupId(this.groupId, this.$store.state.profileId).then(res => {
        this.loading = false;
        console.log(res);
        if (res.data.data.length != 0) {
          this.addedList = res.data.data.map(item => {
            item.yet = 1;
            return item;
          });
        }
        if (this.addedList.length != 0) {
          let arr = this.addedList.map(item => {
            return item.asin;
          });
          this.goodsList = this.goodsList.map(item => {
            if (arr.includes(item.asin)) {
              item.option = 1;
            }
            return;
          });
        }
        this.sendAsin();
      });
    },
    handleCurrentChange(val) {
      this.goodsInfo.current = val;
      this.getListPage();
    },
    getPageInit() {
      this.loading = true;
      pageInit().then(res => {
        this.loading = false;
        this.productStatusList = res.data.data.productStatusList;
        this.searchList = res.data.data.searchList;
        this.getListPage();
        if (this.type != "add") {
          this.getAddedList();
        }
      });
    },
    getListPage() {
      this.loading = true;
      listPage({
        ...this.goodsInfo,
        shopId: this.shopInfo.shopId,
        marketPlaceId: this.shopInfo.marketPlaceId,
        [this.select_Asin]: this.searchByAsin ? this.searchByAsin : null
      }).then(res => {
        this.loading = false;
        console.log(res);
        this.goodsList = res.data.values.map(item => {
          item.option = 0;
          return item;
        });
        this.total = res.data.pageInfo.total;
        if (this.addedList.length != 0) {
          let arr = this.addedList.map(item => {
            return item.asin;
          });
          this.goodsList = this.goodsList.map(item => {
            if (arr.includes(item.asin)) {
              item.option = 1;
            }
            return item;
          });
        }
      });
    },
    find() {
      this.goodsInfo.current = 1;
      this.getListPage();
    }
  }
};
</script>

<style scoped lang='scss'>
.searchByType {
  position: relative;
  top: 2px;
}
::v-deep .goodsBox {
  width: 100%;
  height: 500px;
  border: #f0f0f0 1px solid;
  margin-top: 10px;
  display: flex;
  overflow: hidden;
  padding: 15px 0px 3px 0px;
  .goodTop {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 10px;
    > span:nth-of-type(2) {
      color: #409eff;
      margin-right: 15px;
      cursor: pointer;
    }
  }
  > div {
    width: 50%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .state {
      display: inline-block;
      width: 24px;
      height: 16px;
      vertical-align: middle;
      margin-top: -4px;
      img {
        margin-top: 3px;
      }
    }
    .pic {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin-right: 4px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  > div:nth-of-type(1) {
    border-right: 1px solid #f0f0f0;
    .el-tabs__nav-scroll {
      padding-left: 00px;
      margin-top: 10px;
    }
  }
}
::v-deep .el-input-group__prepend {
    background-color: #fff;
    padding: 0 15px;
  }
::v-deep .el-input-group__append {
    background-color: #fff;
    padding: 0 15px;
  }
</style>
<template>
  <div class="goodsOn">
    <el-tabs type="card" v-model="activeName">
      <el-tab-pane label="分类" name="asinCategorySameAs">
        <span slot="label"
          >分类
          <el-tooltip placement="top">
            <div slot="content" style="width: 250px; line-height: 19px">
              <p>各类商品是按相似性划分在一起的商品，如女士跑鞋。</p>
            </div>
            <i class="iconfont icon-wenhao"></i>
          </el-tooltip>
        </span>
      </el-tab-pane>
      <el-tab-pane label="各个商品" name="asinSameAs">
        <span slot="label"
          >各个商品
          <el-tooltip placement="top">
            <div slot="content" style="width: 250px; line-height: 19px">
              <p>定位到与您广告中宣传的商品类似的各个商品。</p>
            </div>
            <i class="iconfont icon-wenhao"></i>
          </el-tooltip>
        </span>
      </el-tab-pane>
    </el-tabs>

    <el-table
      v-loading="firstLoading"
      v-if="activeName == 'asinCategorySameAs'"
      :data="suggestList"
      max-height="400px"
      cell-style="font-size:14px"
      :show-header="false"
    >
      <el-table-column prop="prop">
        <template slot-scope="scope">
          <p style="color: #999999; font-size: 13px">
            {{ scope.row.path }}
          </p>
          <p style="font-size: 13px">分类：{{ scope.row.name }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="prop" label="label" width="33">
        <template slot-scope="scope">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span
              @click="addLocationGoods(scope.row, scope.$index)"
              style="font-size: 23px; color: #409eff"
              class="el-icon-circle-plus-outline"
              v-if="scope.row.option != 1"
            ></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="prop"
        label="label"
        width="50px"
      >
        <template slot-scope="scope">
          <span
            style="color: #999999; line-height: 1; cursor: pointer"
            @click="getGroupDetailInit(scope.row)"
          >
            细化
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="activeName == 'asinSameAs'">
      <el-tabs v-model="active" @tab-click="clearInput">
        <el-tab-pane label="已建议" name="first"></el-tab-pane>
        <el-tab-pane label="手动输入" name="second"></el-tab-pane>
      </el-tabs>
      <div
        v-if="activeName == 'asinSameAs' && active == 'second'"
        style="margin-bottom: 60px"
      >
        <el-input
          v-model="inputASIN"
          style="margin-top: 10px"
          rows="10"
          cols="43"
          type="textarea"
          placeholder="手动输入ASIN,多个时换行输入 如 
B077ZKF9ZN 
B07X1QPRYQ 
B07V2CLJLV"
        >
        </el-input>
        <el-button
          style="float: right; margin-top: 10px"
          @click="addLocationGoods"
          >添加</el-button
        >
      </div>
      <el-table
        v-if="activeName == 'asinSameAs' && active == 'first'"
        v-loading="secondLoading"
        :data="goodsAsinList"
        style="margin-top: 9px"
        max-height="400px"
        cell-style="font-size:14px;text-align:left"
        header-cell-style="font-size:14px;text-align:left;background:#FAFAFA"
      >
        <el-table-column
          header-align="center"
          align="center"
          prop="recommendedTargetAsin"
          label="ASIN"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          prop="prop"
          width="60px"
          label="添加"
        >
          <template slot-scope="scope">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span
                @click="addLocationGoods(scope.row, scope.$index)"
                style="font-size: 23px; color: #409eff"
                class="el-icon-circle-plus-outline"
                v-if="scope.row.option != 1"
              ></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <p style="text-align: center; padding: 10px 0; margin: 10px 0">
      定位商品数
    </p>
    <p style="text-align: right; color: #409eff; margin: 0 10px 9px 0">
      <span @click="removeAll" style="cursor: pointer">删除全部</span>
    </p>
    <el-table
      v-loading="bottLoading"
      max-height="400px"
      :data="typeGoodsList"
      style="width: 100%"
      cell-style="font-size:14px;text-align:left"
      header-cell-style="font-size:14px;text-align:left;background:#FAFAFA"
    >
      <el-table-column prop="prop" label="分类和商品" width="width">
        <template slot-scope="scope" style="font-size: 14px">
          <div v-if="scope.row.yet != 1">
            <p
              v-if="
                scope.row.amzAdTargetExpressionList[0].type ==
                'asinCategorySameAs'
              "
              style="color: #999999; font-size: 15px"
            >
              {{ scope.row.path }}
            </p>
            <p
              v-if="
                scope.row.amzAdTargetExpressionList[0].type ==
                'asinCategorySameAs'
              "
              style="font-size: 15px"
            >
              <span
                >分类：{{ scope.row.amzAdTargetExpressionList[0].name }}</span
              >&ensp;&ensp;
              <span v-if="scope.row.name != undefined"
                >品牌：{{ scope.row.name }}</span
              >&ensp;&ensp;
              <span
                v-if="
                  scope.row.asinPriceGreaterThan != undefined &&
                  scope.row.asinPriceGreaterThan != '' &&
                  scope.row.asinPriceLessThan != undefined &&
                  scope.row.asinPriceLessThan != ''
                "
                >价格区间：{{
                  scope.row.asinPriceGreaterThan +
                  " - " +
                  scope.row.asinPriceLessThan
                }}</span
              >&ensp;&ensp;
              <span
                v-if="
                  (scope.row.asinPriceLessThan != '' &&
                    scope.row.asinPriceGreaterThan == '') ||
                  (scope.row.asinPriceGreaterThan != '' &&
                    scope.row.asinPriceLessThan == '')
                "
                >商品价格：
                <span v-if="scope.row.asinPriceLessThan != ''"
                  >不高于 {{ scope.row.asinPriceLessThan }}</span
                >
                <span v-if="asinPriceGreaterThan != ''"
                  >不低于 {{ scope.row.asinPriceGreaterThan }}</span
                > </span
              >&nbsp;&nbsp;
              <span v-if="scope.row.prime != undefined && scope.row.prime != ''"
                >配送：{{ scope.row.prime }}</span
              >&nbsp;&nbsp;
              <span
                v-if="
                  scope.row.asinReviewRatingBetween != undefined &&
                  scope.row.asinReviewRatingBetween.length != 0
                "
                >评分：{{
                  scope.row.asinReviewRatingBetween[0] +
                  "-" +
                  scope.row.asinReviewRatingBetween[1]
                }}</span
              >
            </p>
            <p
              v-if="scope.row.amzAdTargetExpressionList[0].type == 'asinSameAs'"
            >
              {{ scope.row.amzAdTargetExpressionList[0].name }}
            </p>
          </div>
          <div
            v-if="scope.row.yet == 1"
            v-dompurify-html="scope.row.value"
            style="font-size: 14px"
          ></div>
        </template>
      </el-table-column>
      <el-table-column
        width="150px"
        prop="prop"
        label="竞价"
        header-align="center"
      >
        <template slot-scope="scope">
          <el-input
            :disabled="scope.row.yet == 1 ? true : false"
            v-model="scope.row.bid"
            placeholder="竞价"
          >
            <span slot="append">{{ currency }}</span>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        prop="prop"
        label="删除"
        width="65px"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.yet != 1"
            type="text"
            class="el-icon-delete"
            style="font-size: 23px"
            @click="remove(scope.row, scope.$index)"
          ></el-button>
          <span style="color: #999999" v-if="scope.row.yet == 1">已存在</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title=""
      :visible.sync="detail"
      width="600px"
      append-to-body
      :before-close="dialogBeforeClose"
    >
      <template slot="title">
        <p>细化产品分类：&nbsp;{{ path }}</p>
        <p
          style="
            color: #999999;
            font-size: 14px;
            margin-top: 10px;
            border-bottom: 1px #d9d9d9 solid;
            padding-bottom: 10px;
          "
        >
          根据特定品牌、价格范围、星级和Prime配送资格，细化分类
        </p>
      </template>
      <div class="form">
        <el-form label-position="top" ref="form" :model="form">
          <el-form-item prop="asinBrandSameAs">
            <p>品牌</p>
            <el-select
              style="width: 100%; margin: 10px 0"
              placeholder="请输入或选择品牌"
              v-model="form.asinBrandSameAs"
              multiple
              filterable
              allow-create
              default-first-option
            >
              <el-option
                v-for="item in brandList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="asinPriceGreaterThan">
            <p>商品价格范围</p>
            <el-input
              v-model="form.asinPriceGreaterThan"
              placeholder="无最低商品价格"
              el-input
              @input="minPrice"
              style="width: 180px"
            >
              <el-button slot="append">{{ currency }}</el-button>
            </el-input>
            -
            <el-input
              v-model="form.asinPriceLessThan"
              placeholder="无最高商品价格"
              style="width: 180px"
              @input="maxPrice"
            >
              <el-button slot="append">{{ currency }}</el-button>
            </el-input>
          </el-form-item>
          <el-form-item prop="asinReviewRatingBetween">
            <p>查看星级评定</p>

            <div class="block" style="margin: 15px 30px 20px 10px">
              <el-slider
                v-model="form.asinReviewRatingBetween"
                range
                :marks="marks"
                show-stops
                :max="5"
              >
              </el-slider>
            </div>
          </el-form-item>
          <el-form-item prop="asinIsPrimeShippingEligible">
            <p style="margin: 20px 0">配送</p>
            <el-radio
              v-model="form.asinIsPrimeShippingEligible"
              :label="String(item.id)"
              v-for="item in primeTypeList"
              :key="item.id"
              >{{ item.value }}</el-radio
            >
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addElaboration">确 定</el-button>
      </div>
    </el-dialog>
    <div style="text-align: right; padding-top: 10px">
      <el-button @click="NewProductPositioning" type="primary" slot="sure"
        >确定新增商品定位</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  goodsType,
  getTargetProductRecommendations,
  listExistTarget,
  groupDetailInit,
  addTargets
} from "@/api/launch.js";
export default {
  props: ["asinList", "currency", "groupId","campaignId"],
  data() {
    return {
      list: [],
      detail: false,
      suggestList: [],
      firstLoading: false,
      secondLoading: false,
      activeName: "asinCategorySameAs",
      active: "first",
      goodsAsinList: [],
      typeGoodsList: [],
      bottLoading: false,
      inputASIN: "",
      path: "",
      form: {
        asinBrandSameAs: "",
        asinPriceGreaterThan: "",
        asinPriceLessThan: "",
        asinReviewRatingBetween: [1, 5],
        asinIsPrimeShippingEligible: "",
        asinIsPrimeShippingEligible: ""
      },
      primeTypeList: [],
      detailTypeList: [],
      brandList: [],
      marks: { 0: "0星", 1: "1星", 2: "2星", 3: "3星", 4: "4星", 5: "5星" },
      detalLineInfo: {}
    };
  },
  watch: {
    asinList(newval, oldVal) {
      console.log(newval);
      if (newval.length != 0) {
        this.getSuggestList();
        this.getTargetAsin();
      }
    },
    groupId(newval, old) {
      this.getTypeGoodsList();
    },
    typeGoodsList: {
      handler(newval, oldval) {
        let arr = newval.filter(item => {
          return item.yet != 1;
        });
        if (arr.length == 0) return;
        let list = arr.map(item => {
          if (item.name == undefined) {
            return item;
          }
          if (item.name != undefined) {
            let postList = [];
            postList.push({
              name: item.name,
              type: "asinBrandSameAs",
              value: item.id
            });
            // 区间或价格
            if (
              item.asinPriceLessThan != "" &&
              item.asinPriceGreaterThan != ""
            ) {
              postList.push({
                name: item.asinPriceGreaterThan + "-" + item.asinPriceLessThan,
                type: "asinPriceBetween",
                value: item.asinPriceGreaterThan + "-" + item.asinPriceLessThan
              });
            } else if (
              item.asinPriceLessThan != "" &&
              item.asinPriceGreaterThan == ""
            ) {
              postList.push({
                name: item.asinPriceLessThan,
                type: "asinPriceLessThan",
                value: item.asinPriceLessThan
              });
            } else if (
              item.asinPriceLessThan == "" &&
              item.asinPriceGreaterThan != ""
            ) {
              postList.push({
                name: item.asinPriceGreaterThan,
                type: "asinPriceGreaterThan",
                value: item.asinPriceGreaterThan
              });
            }
            // 星数
            if (item.asinReviewRatingBetween != "") {
              postList.push({
                name:
                  item.asinReviewRatingBetween[0] +
                  "-" +
                  item.asinReviewRatingBetween[1],
                type: "asinReviewRatingBetween",
                value:
                  item.asinReviewRatingBetween[0] +
                  "-" +
                  item.asinReviewRatingBetween[1]
              });
            }
            // 配送
            if (item.asinIsPrimeShippingEligible != "") {
              postList.push({
                name: item.asinIsPrimeShippingEligible,
                type: "asinIsPrimeShippingEligible",
                value: item.asinIsPrimeShippingEligible
              });
            }
            if (item.amzAdTargetExpressionList.length != 0) {
              postList.push(item.amzAdTargetExpressionList[0]);
            }
            return { amzAdTargetExpressionList: postList, bid: item.bid };
          }
        });
        this.list = list;
        this.$emit("getAddTargetList", list);
      },
      deep: true
    }
  },
  mounted() {
    this.getTypeGoodsList();
  },
  computed: {},
  methods: {
    NewProductPositioning() {
      const arr = this.list.filter(item=>{
        return !item.bid
      })
      if(arr.length>0) return this.$message.error('请输入竞价')
      this.bottLoading=true
      addTargets(
        {
          adGroupId: this.groupId,
          campaignId: this.campaignId,
          expressionType: "",
          targets: this.list
        },
        this.$store.state.profileId
      ).then(res => {
        this.bottLoading = false;
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.getTypeGoodsList()
        } else {
          this.$message.error(res.data.message);
        }
      }).catch(()=>{
        this.bottLoading=false
      })
    },
    removeAll() {
      this.typeGoodsList = this.typeGoodsList.filter(item => {
        return item.yet == 1;
      });
      this.getSuggestList();
      this.getTargetAsin();
    },
    dialogBeforeClose() {
      this.detail = false;
      this.$refs.form.resetFields();
      this.form.asinPriceLessThan = "";
    },
    maxPrice() {
      if (
        this.form.asinPriceGreaterThan != "" &&
        this.form.asinPriceGreaterThan > this.form.asinPriceLessThan
      ) {
        this.form.asinPriceLessThan = "";
        this.$message.warning("若同时输入最高/最低，最高价必须大于最低价");
      }
    },
    minPrice() {
      if (
        this.form.asinPriceLessThan != "" &&
        this.form.asinPriceGreaterThan > this.form.asinPriceLessThan
      ) {
        this.form.asinPriceLessThan = "";
        this.$message.warning("若同时输入最高/最低，最高价必须大于最低价");
      }
    },
    addElaboration() {
      let prime = "";
      this.primeTypeList.forEach(item => {
        if (item.id == this.form.asinIsPrimeShippingEligible)
          prime = item.value;
      });
      // 是否取消加号
      // this.categoriesList.forEach((item,index)=>{
      //     if(this.addInfo.elaborationRow.id==item.id) this.categoriesList[index].operation=1
      // })
      let arr = [];
      this.form.asinBrandSameAs.forEach(item => {
        this.brandList.forEach(i => {
          if (i.id == item) arr.push({ id: item, name: i.name });
        });
      });
      arr.forEach(item => {
        this.typeGoodsList.push({
          amzAdTargetExpressionList: [
            {
              name: this.detalLineInfo.name,
              value: this.detalLineInfo.id,
              type: this.activeName
            }
          ],
          bid: "",
          path: this.path,
          operation: 1,
          prime: prime,
          ...this.form,
          ...item
        });
      });
      this.dialogBeforeClose();
    },
    getGroupDetailInit(row) {
      this.firstLoading = true;
      this.path = row.path;
      this.detalLineInfo = row;
      groupDetailInit({ categoryId: row.id }, this.$store.state.profileId).then(
        res => {
          this.detail = true;
          this.firstLoading = false;
          console.log(res);
          this.brandList = res.data.data.brandList;
          this.detailTypeList = res.data.data.detailTypeList;
          this.primeTypeList = res.data.data.primeTypeList;
        }
      );
    },
    remove(row, index) {
      this.typeGoodsList.splice(index, 1);
      this.suggestList.forEach((item, ind) => {
        if (item.path == row.path) {
          this.suggestList[ind].option = 0;
        }
      });
      this.goodsAsinList.forEach((item, ind) => {
        if (
          item.recommendedTargetAsin == row.amzAdTargetExpressionList[0].value
        ) {
          this.goodsAsinList[ind].option = 0;
        }
      });
    },
    clearInput() {
      this.inputASIN = "";
    },
    addLocationGoods(row, index) {
      console.log(row, index);
      row.option = 1;
      if (this.activeName == "asinCategorySameAs") {
        this.suggestList[index].option = 1;
        this.typeGoodsList.push({
          amzAdTargetExpressionList: [
            { name: row.name, value: row.id, type: this.activeName }
          ],
          bid: "",
          path: row.path
        });
      }
      if (this.activeName == "asinSameAs") {
        if (this.active == "second") {
          const reg = /^(b|B){1}[0-9]{1}[0-9]{1}\w{7}$/;
          let list = this.inputASIN.split(/[(\r\n)\r\n]+/);
          const arr = [];
          let num = 0;
          list = Array.from(new Set(list));
          list.forEach(item => {
            if (reg.test(item)) {
              arr.push(item);
            } else {
              num++;
            }
          });
          if (num > 0) {
            this.$message.warning(`有${num}条格式错误`);
            return;
          }
          let amzAdTargetExpressionList = arr.map(item => {
            return {
              name: this.item,
              type: this.activeName,
              value: this.item
            };
          });
          this.inputASIN = "";
          this.typeGoodsList.push({
            amzAdTargetExpressionList: [
              {
                name: this.inputASIN,
                type: this.activeName,
                value: this.inputASIN
              }
            ]
          });
        } else {
          this.goodsAsinList[index].option = 1;
          this.typeGoodsList.push({
            bid: "",
            amzAdTargetExpressionList: [
              {
                name: row.recommendedTargetAsin,
                type: this.activeName,
                value: row.recommendedTargetAsin
              }
            ]
          });
        }
      }
      console.log(this.typeGoodsList);
    },
    getSuggestList() {
      this.firstLoading = true;
      goodsType(this.$store.state.profileId, this.asinList).then(res => {
        this.firstLoading = false;
        const arr = [];
        this.typeGoodsList.forEach(item => {
          arr.push(item.path);
        });
        console.log(arr);
        this.suggestList = res.data.data.map(item => {
          if (arr.includes(item.path)) {
            item.option = 1;
          } else {
            item.option = 0;
          }
          return item;
        });
      });
    },
    getTargetAsin() {
      this.secondLoading = true;
      getTargetProductRecommendations(
        this.asinList,
        this.$store.state.profileId
      ).then(res => {
        this.secondLoading = false;
        let arr = [];
        this.typeGoodsList.forEach(item => {
          if (item.value) {
            arr.push(item.value);
          } else {
            arr.push(item.amzAdTargetExpressionList[0].value);
          }
        });
        this.goodsAsinList = res.data.data.map(item => {
          if (arr.includes(item.recommendedTargetAsin)) {
            item.option = 1;
          } else {
            item.option = 0;
          }
          return item;
        });
      });
    },
    getTypeGoodsList() {
      this.bottLoading = true;
      listExistTarget({ groupId: this.groupId }).then(res => {
        this.bottLoading = false;
        this.typeGoodsList = res.data.data.map(item => {
          item.yet = 1;
          return item;
        });
      });
    }
  }
};
</script>

<style scoped lang='scss'>
.goodsOn {
  border: 1px solid #f0f0f0;
  padding: 8px;
}
::v-deep .el-input-group__prepend {
  background-color: #fff;
  padding: 0;
}
::v-deep .el-input-group__append {
  background-color: #fff;
  padding: 0 15px;
}
::v-deep .el-dialog__body {
  padding: 9px 20px;
  div {
    margin: 0;
  }
}
::v-deep .form .el-select {
  margin: 0 !important;
}
::v-deep .el-slider__marks-text {
  top: 8px;
}
::v-deep .el-slider__marks-text:nth-of-type(6) {
  top: 8px;
  left: 94% !important;
  margin-left: 9px;
}
</style>
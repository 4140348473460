import request from '@/utils/request'
// 查询初始化
export function pageInits(id) {
    return request({
        method:'get',
        url:'/api/ad/target/pageInit',
        headers:{
          'profileId':id
        }
    });
}
// 查询分页
export function getListPage(params) {
    return request({
      url: "/api/ad/target/listPage",
      params,
      method: "get",
    }) 
  }

  // 新增初始化
export function addInit(profileId) {
  return request({
      method:'get',
      url:'/api/ad/target/addInit',
      headers:{
        'profileId':profileId
    }
  });
}
//库存管理
export function stockManage(params) {
  return request({
      method:'get',
      url:'/api/amazon/asin/stockListPage',
      params,
  });
}
//查询建议分类投放根据Asin
export function goodsType(profileId,asinList) {
  return request({
      method:'get',
      url:'/api/ad/target/getCategories/'+asinList,
      headers:{
        'profileId':profileId
    }
  });
}
//查询建议商品投放根据Asin
export function getTargetProductRecommendations(params,profileId) {
  return request({
      method:'get',
      url:`/api/ad/target/getTargetProductRecommendations/${params}`,
      headers:{
        'profileId':profileId
    }
  });
}
//查询已存在投放
export function listExistTarget(params) {
  return request({
      method:'get',
      url:'/api/ad/target/listExistTarget',
      params,
  });
}
//新增广告组商品投放
export function addTargets(data,profileId) {
  return request({
      method:'post',
      url:'/api/ad/target/addTargets',
      data,
      headers:{
        "profileId":profileId
    }
  });
}
//搜索获取品牌
export function getTargetsBrands(params,profileId) {
  return request({
      method:'get',
      url:'/api/ad/target/getTargetsBrands',
      params,
      headers:{
        "profileId":profileId
    }
  });
}
//投放批量操作
export function BatchKeyword(data, profileId) {
  return request({
    url: "/api/ad/target/batchUpdate",
    data,
    headers: {
      "profileId": profileId
    },
    method: "put"
  })
}
// 新增否定投放
export function addNeTargets(data, profileId) {
  return request({
    url: "/api/ad/target/addNeTargets",
    data,
    headers: {
      "profileId": profileId
    },
    method: "post"
  })
}
// 细化初始化
export function groupDetailInit(params,profileId) {
  return request({
      url:'/api/ad/groups/groupDetailInit',
      method:'get',
      params,
      headers:{
          "profileId":profileId
      }
  });
}
//修改竞价
export function modifyBid(data, profileId) {
  return request({
    url: "/api/AmzAdCampaigns/updateBid",
    data,
    headers: {
      "profileId": profileId
    },
    method: "put"
  })
}
// 投放获取建议竞价
export function getKeywordBidRecommendations(targetId, profileId) {
  return request({
    url: "/api/ad/target/getTargetsBidRecommendations/" + targetId,
    method: "get",
    headers: {
      "profileId": profileId
    },
  })
}
//查询否定投放
export function listNegativeTarget(params) {
  return request({
      method:'get',
      url:'/api/ad/target/listNegativeTarget',
      params,
  });
}
// 修改否定投放状态 否定商品归档
export function archivedNeTarget(data,profileId) {
  return request({
      url:'/api/ad/target/archivedNeTarget',
      method:'put',
      data,
      headers:{
          "profileId":profileId
      }
  });
}
//查询已推广的商品通过组id
export function listByGroupId(id,profileId) {
  return request({
      method:'get',
      url:'/api/ad/product/listByGroupId',
      params:{
        groupsId:id
    },
      headers: {
        "profileId": profileId
      },
  });
}
// 关注与取消关注
export function focusOn(id) {
  return request({
      url:'/api/cpc/focus/add',
      method:'get',
      params:{
          adGroupId:id
      }
  });
}
export function focusOff(id) {
  return request({
      url:'/api/cpc/focus/cancel',
      method:'get',
      params:{
          adGroupId:id
      }
  });
}

// 表现明细查询
export function listByTarget(params) {
  return request({
    url: "/api/ad/target/listByTarget",
    params,
    method: "get"
  })
}
<template>
  <div class="Negat">
    <el-collapse accordion>
      <el-collapse-item>
        <template slot="title">
          <span style="margin-left: 20px">否定商品（可选）</span>
          <el-tooltip placement="top">
            <div slot="content" style="width: 250px; line-height: 19px">
              <p>
                否定商品投放会防止您的广告在顾客的搜索内容与您的否定商品选择匹配时展示。
              </p>
              <br />
              <p>这有助于排除不相关的搜索，从而减少广告费用。</p>
            </div>
            <i class="iconfont icon-wenhao"></i>
          </el-tooltip>
        </template>
        <div
          v-loading="loading"
          class="noGoods"
          style="border-top: 1px solid #f0f0f0; padding: 0 10px"
        >
          <div>
            <el-tabs type="card" v-model="noGoodsType" @tab-click="clear">
              <el-tab-pane
                v-if="type == '商'"
                label="排除品牌"
                name="asinBrandSameAs"
              >
                <span slot="label"
                  >排除品牌
                  <el-tooltip placement="top">
                    <div slot="content" style="width: 250px; line-height: 19px">
                      <p>
                        这是一项高级策略，可防止您的广告展示在特定搜索结果和商品详情页中。
                      </p>
                    </div>
                    <i class="iconfont icon-wenhao"></i>
                  </el-tooltip>
                </span>
              </el-tab-pane>
              <el-tab-pane label="排除全部" name="asinSameAs">
                <span slot="label"
                  >排除全部
                  <el-tooltip placement="top">
                    <div slot="content" style="width: 250px; line-height: 19px">
                      <p>
                        这是一项高级策略，可防止您的广告展示在特定搜索结果和商品详情页中。
                      </p>
                    </div>
                    <i class="iconfont icon-wenhao"></i>
                  </el-tooltip>
                </span>
              </el-tab-pane>
            </el-tabs>
            <div v-if="noGoodsType == 'asinSameAs'">
              <el-input
                v-model="noGoods"
                style="margin-top: 10px"
                rows="8"
                type="textarea"
                placeholder="手动输入ASIN，多个时换行输入"
              ></el-input>
              <el-button
                @click="toTargetsBrandsList"
                style="float: right; margin: 10px 0px 0 15px"
                >添加</el-button
              >
            </div>
            <div v-if="noGoodsType == 'asinBrandSameAs'">
              <el-input
                placeholder="请输入内容"
                v-model="targetsBrandsInfo.keyword"
                class="input-with-select"
                style="margin-top: 10px"
              >
                <el-button
                  @click="getTargetsBrandsList"
                  :loading="searchLoading"
                  slot="append"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
              <div>
                <el-table
                  v-loading="searchLoading"
                  height="250px"
                  :show-header="false"
                  :data="targetsBrandsList"
                  style="width: 100%; margin-top: 10px"
                  cell-style="font-size:14px"
                  header-cell-style="background:#FAFAFA;font-size:14px"
                >
                  <el-table-column prop="name" label="label" width="width">
                  </el-table-column>
                  <el-table-column prop="prop" label="label" width="50">
                    <template slot-scope="scope">
                      <span
                        v-if="scope.row.option == 0"
                        @click="toTargetsBrandsList(scope.row, scope.$index)"
                        style="font-size: 23px; color: #409eff"
                        class="el-icon-circle-plus-outline"
                      ></span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <div>
            <span
              v-if="addedLength > 0"
              @click="archivedNeTarget"
              style="color: #409eff; cursor: pointer; margin-right: 10px"
              >归档</span
            >
            <span>已添加({{ addedLength }})</span>
            <el-button @click="deleteAll" type="text" style="float: right"
              >删除全部</el-button
            >
            <el-table
              :data="addList"
              style="width: 100%"
              height="310px"
              @selection-change="handleSelectionChange"
              header-cell-style="background:#DFEFEFE;font-size:14px"
            >
              <el-table-column
                :selectable="selectable"
                type="selection"
                width="55"
              >
              </el-table-column>
              <el-table-column prop="prop" label="品牌/商品" width="width">
                <template slot-scope="scope">
                  <p style="color: #999999; font-size: 14px">
                    <span v-if="scope.row.type == 'asinBrandSameAs'"
                      >品牌：</span
                    ><span v-if="scope.row.type == 'asinSameAs'">ASIN：</span>
                    <span v-if="scope.row.yet == 1">{{ scope.row.value }}</span>
                    <span v-if="scope.row.yet != 1">{{ scope.row.name }}</span>
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                prop="prop"
                header-align="center"
                align="center"
                label="删除"
                width="70"
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.yet != 1"
                    style="font-size: 23px; color: #409eff"
                    class="el-icon-delete"
                    @click="delItem(scope.$index, scope.row)"
                  ></span>
                  <span
                    style="color: #999999; font-size: 14px"
                    v-if="scope.row.yet == 1"
                    >已存在</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <div style="text-align: right; padding-top: 10px">
              <el-button @click="addNeTargets" type="primary" slot="sure"
                >确定新增否定商品</el-button
              >
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {
  getTargetsBrands,
  listNegativeTarget,
  archivedNeTarget,
  addNeTargets
} from "@/api/launch.js";
export default {
  props: ["groupId", "type", "campaignId"],
  data() {
    return {
      noGoodsType: "asinBrandSameAs",
      noGoods: "",
      targetsBrandsInfo: {
        keyword: "",
        categoryId: ""
      },
      searchLoading: false,
      targetsBrandsList: [],
      addList: [],
      achivedIds: [],
      loading: false
    };
  },
  computed: {
    addedLength() {
      return this.addList.map(item => item.yet != 1).length;
    }
  },
  watch: {
    groupId(newval, oldval) {
      this.getListNegativeTarget();
      this.targetsBrandsList = [];
      this.targetsBrandsInfo.keyword = "";
    },
    type: {
      handler(newval, oldval) {
        if (newval == "商") {
          this.noGoodsType = "asinBrandSameAs";
        } else {
          this.noGoodsType = "asinSameAs";
        }
      },
      immediate: true
    }
  },
  methods: {
    addNeTargets() {
      this.loading = true;
      addNeTargets(
        {
          amzAdTargetExpressionList: this.addList.filter(item => item.yet != 1),
          adGroupId: this.groupId,
          campaignId: this.campaignId,
          expressionType: "manual"
        },
        this.$store.state.profileId
      ).then(res => {
        this.loading = false;
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.getListNegativeTarget();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleSelectionChange(val) {
      this.achivedIds = val.map(item => item.targetId);
    },
    archivedNeTarget() {
      this.loading = true;
      archivedNeTarget(
        { type: "negativeTargets", targetIds: this.achivedIds },
        this.$store.state.profileId
      )
        .then(res => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.getListNegativeTarget();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectable(row, index) {
      if (row.yet == 1) {
        return true;
      } else {
        return false;
      }
    },
    getListNegativeTarget() {
      this.loading = true;
      listNegativeTarget({ groupId: this.groupId })
        .then(res => {
          this.loading = false;
          this.addList = res.data.data.map(item => {
            item.yet = 1;
            return item;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    delItem(index, row) {
      this.targetsBrandsList.forEach((item, ind) => {
        if (row.name) {
          if (item.name == row.name) {
            this.targetsBrandsList[ind].option = 0;
          }
        }
      });
      this.addList.splice(index, 1);
    },
    clear() {
      this.targetsBrandsInfo.keyword = "";
    },
    toTargetsBrandsList(row, index) {
      if (this.noGoods == "" && this.noGoodsType == "asinSameAs") {
        this.$message.warning("请输入内容");
        return;
      }
      if (this.noGoods == "" && this.noGoodsType == "asinBrandSameAs") {
        this.addList.push({
          name: row.name,
          type: this.noGoodsType,
          value: row.id
        });
        this.targetsBrandsList[index].option = 1;
      } else {
        this.addListByAsin();
      }
    },
    addListByAsin() {
      const reg = /^(B|b)[0-9]{1}[0-9]{1}\w{7}$/;
      const list = this.noGoods.split(/[(\r\n)\r\n]+/);
      const arr = [];
      let num = 0;
      list.forEach(item => {
        if (!reg.test(item)) num++;
      });
      if (num > 0) {
        this.$message.warning(`有${num}个ASIN格式有误`);
        return;
      }
      list.forEach(item => {
        const flag = this.addList.some(i => {
          return item == i.value && i.type == "asinSameAs";
        });
        if (!flag) {
          arr.push({ name: item, value: item, type: "asinSameAs" });
        }
      });
      this.noGoods = "";
      this.addList = [...this.addList, ...arr];
    },
    getTargetsBrandsList() {
      if (
        this.noGoodsType == "asinBrandSameAs" &&
        this.targetsBrandsInfo.keyword == ""
      ) {
        this.$message.error("请输入内容");
        return;
      }
      this.searchLoading = true;
      getTargetsBrands(
        this.targetsBrandsInfo,
        this.$store.state.profileId
      ).then(res => {
        this.searchLoading = false;
        if (res.data.code == 200) {
          this.targetsBrandsList = res.data.data.map(item => {
            item.option = 0;
            return item;
          });
          this.addList.forEach(i => {
            this.targetsBrandsList.forEach(item => {
              if (
                (item.name == i.name && i.type == "asinBrandSameAs") ||
                item.name == i.value
              ) {
                item.option = 1;
              }
            });
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    deleteAll() {
      console.log(1);
      this.addList = this.addList.filter(item => item.yet == 1);
      this.targetsBrandsList = this.targetsBrandsList.map(item => {
        item.option = 0;
        return item;
      });
    }
  }
};
</script>

<style scoped lang='scss'>
.noGoods {
  display: flex;
  > div {
    flex: 1;
    margin: 10px;
    padding-right: 15px;
  }
  > div:nth-of-type(1) {
    border-right: 1px solid #f0f0f0;
  }
}
::v-deep .el-input-group__prepend {
  background-color: #fff;
  padding: 0;
}
::v-deep .el-input-group__append {
  background-color: #fff;
  padding: 0 15px;
}
.Negat {
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 7px;
}
</style>